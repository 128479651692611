import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

export enum MessageTypes {
    error = "error",
    warning = "warning",
    info = "info",
    success = "success",
}

export const Message = ({
    open,
    showMessage,
    message,
    type,
}: {
    open: boolean;
    showMessage: (_: boolean) => void;
    message: string;
    type: MessageTypes;
}) => {
    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={open}
            key="user-login"
            autoHideDuration={6000}
            onClose={() => showMessage(false)}
        >
            <Alert
                severity={type}
                variant="filled"
                onClose={() => showMessage(false)}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Message;
